import { Link } from "react-router-dom";

export const Thanks = () => {
  return (
    <>
      <div className="h-screen w-screen bg-gray-100 flex items-center">
        <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
          <div className="w-full md:w-5/12">
            {/* <div>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  className="w-[100px] mb-5"
                  width="100px"
                  height="100px"
                  viewBox="0 0 400 400"
                >
                  <g>
                    <g>
                      <path d="M199.996,0C89.713,0,0,89.72,0,200s89.713,200,199.996,200S400,310.28,400,200S310.279,0,199.996,0z M199.996,373.77C104.18,373.77,26.23,295.816,26.23,200c0-95.817,77.949-173.769,173.766-173.769c95.817,0,173.771,77.953,173.771,173.769C373.768,295.816,295.812,373.77,199.996,373.77z" />
                      <path d="M272.406,134.526L169.275,237.652l-41.689-41.68c-5.123-5.117-13.422-5.12-18.545,0.003c-5.125,5.125-5.125,13.425,0,18.548l50.963,50.955c2.561,2.558,5.916,3.838,9.271,3.838s6.719-1.28,9.279-3.842c0.008-0.011,0.014-0.022,0.027-0.035L290.95,153.071c5.125-5.12,5.125-13.426,0-18.546C285.828,129.402,277.523,129.402,272.406,134.526z" />
                    </g>
                  </g>
                </svg>
              </div> */}
            <div className="text-5xl font-dark font-bold text-slate-600 mb-5">
              ¡Gracias!
            </div>
            <p className="text-2xl md:text-3xl font-light leading-normal mb-5">
              Tu mensaje ha sido enviado
            </p>
            <p className="mb-8">¡Te contactaremos pronto!</p>
            <Link
              to="/"
              className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-green-600 focus:ring-green-300 transition duration-150 ease-in-out hover:bg-green-500 rounded-lg border border-green-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
            >
              {`Volver al Home page`}
            </Link>
          </div>
          <div className="max-w-lg"></div>
        </div>
      </div>
    </>
  );
};
