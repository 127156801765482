import { Link } from "react-router-dom";

/**
 * Not found (404) component
 */
export const NotFound = () => (
  <div className="h-screen w-screen bg-gray-100 flex items-center">
    <div className="container flex flex-col md:flex-row items-center justify-center px-5 text-gray-700">
      <div className="max-w-md">
        <div className="text-7xl font-dark font-bold text-indigo-600">404</div>
        <p className="text-2xl md:text-3xl font-light leading-normal mb-5">
          Lo sentimos, no hemos podido encontrar esta página
        </p>
        <p className="mb-8">
          Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra
          página de inicio.
        </p>

        <Link
          to={"/"}
          className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-indigo-600 focus:ring-green-300 transition duration-150 ease-in-out hover:bg-indigo-500 rounded-lg border border-indigo-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
        >
          {`Volver al Home page`}
        </Link>
      </div>
      <div className="max-w-lg"></div>
    </div>
  </div>
);
