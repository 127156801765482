/**
 * Homepage Navigation component
 */
export const Navigation = (props) => {
  const { data } = props;

  return (
    <nav className="w-full hidden sm:flex">
      <div className="container mx-auto flex items-center justify-center">
        <div className="w-11/12 md:w-11/12 lg:w-10/12">
          <div className="py-4 md:py-0 container mx-auto flex items-center justify-between">
            <div aria-label="logo" role="img">
              <img src={data?.nav_logo.url} alt="logo" style={{ width: 210 }} />
            </div>
            <div>
              <ul className="flex text-3xl md:text-base items-center py-10 md:flex flex-col md:flex-row justify-center fixed md:relative top-0 bottom-0 left-0 right-0 bg-white md:bg-transparent z-20">
                {data?.list.map((item, index) => (
                  <li
                    key={index}
                    className="hover:text-emerald-600 cursor-pointer text-base lg:text-md pt-10 md:pt-0 md:ml-5 lg:ml-10"
                  >
                    <a href={`#${item.anchor}`}>{item?.text}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
