import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, Mousewheel, EffectFade, Autoplay } from "swiper";

// Components
import { Navigation } from "./Navigation";
import { Sidenav } from "./Sidenav";

/**
 * Homepage header component
 */
export default function Header(props) {
  const { data, navigation } = props;

  return (
    <>
      <div className="relative bg-white overflow-hidden">
        <Navigation data={navigation} />
        <Sidenav data={navigation} />
        <div className="relative before:absolute before:bg-emerald-500 before:w-0 sm:before:w-1/3 before:h-full before:right-0 pt-20 md:pt-0">
          <div className="absolute flex items-center h-full right-[-50%]  sm:right-[-3rem] z-[0]">
            <svg
              className="h-full sm:h-5/6 hidden sm:flex"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 250.062 215.192"
            >
              <g transform="translate(3.957 3)">
                <path
                  className="lineAnimate"
                  d="M169.884,0H58.709l-13.5,47.912h106.62c20.244,0,32.054,2.868,27.667,18.726S162.63,85.87,139.011,85.87H34.584L0,209.192H121.8c41.5,0,84.352-12.147,95.823-54.66,6.242-22.1,0-38.8-29.017-45.719A70.855,70.855,0,0,0,241.077,58.2C251.368,20.919,219.314,0,169.884,0Zm-9.11,143.735c-4.386,15.858-15.689,17.714-58.2,17.714H75.41L85.2,126.527h27.161C155.038,126.19,165.16,128.552,160.774,143.735Z"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </div>
          <div className="container relative mx-auto flex items-center justify-center py-20">
            <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-col items-start">
              <div className="grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-10 items-center">
                <div className="mb-5 sm:mb-0">
                  <h1 className="w-4/7 sm:w-full text-slate-900 text-4xl sm:text-2xl md:text-3xl lg:text-5xl xl:text-5xl text-left font-semibold md:leading-10">
                    {data?.title}
                  </h1>
                  <p className="mt-5 sm:mt-10 mb-5 sm:mb-5 lg:w-8/12 text-left text-md text-slate-600">
                    {data?.subtitle}
                  </p>
                  <ul className="my-10 sm:my-5 w-full lg:w-4/6">
                    {data?.list.map((item, i) => (
                      <li className="text-sm flex items-center mb-2" key={i}>
                        <i
                          className={`lni ${item.icon_class} text-emerald-600`}
                          style={{ fontSize: 25 }}
                        ></i>
                        <span className="mx-3 text-slate-600">
                          {item.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <div className="flex">
                    <a
                      href={data?.button_contact_link.url}
                      className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-emerald-500 focus:ring-emerald-300 block transition duration-150 ease-in-out hover:bg-emerald-600 rounded-md border border-emerald-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
                    >
                      {data?.button_contact_text}
                    </a>
                  </div>
                </div>
                <div className="relative">
                  <figure
                    className="absolute z-[0]"
                    style={{
                      right: "-75px",
                      top: "-75px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="150"
                      height="150"
                      viewBox="0 0 150 150"
                    >
                      <defs>
                        <linearGradient id="gde1" x1="0.5" x2="0.5" y2="1">
                          <stop offset="0" stopColor="#4ca585" />
                          <stop offset="1" stopColor="#f2fcff" />
                        </linearGradient>
                      </defs>
                      <circle cx="75" cy="75" r="75" fill="url(#gde1)" />
                    </svg>
                  </figure>
                  <figure
                    className="absolute z-[3]"
                    style={{
                      bottom: "-50px",
                      left: "-50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                    >
                      <circle cx="50" cy="50" r="50" fill="url(#gde1)" />
                    </svg>
                  </figure>

                  <Swiper
                    modules={[Pagination, A11y, Mousewheel, EffectFade, Autoplay]}
                    pagination={{ clickable: true }}
                    mousewheel
                    className="mySwiper"
                    slidesPerView={1}
                    spaceBetween={50}
                    effect="fade"
                    fadeEffect={{
                      crossFade: true
                    }}
                    autoplay={{
                      delay: 2000
                    }}
                    loop
                  >
                    {data.gallery.map((item, index) => (
                      <SwiperSlide key={index}>
                        <figure>
                          <img
                            className="relative flex rounded-xl"
                            src={item?.image1.url}
                            alt=""
                          />
                        </figure>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
