import { Routes, BrowserRouter, Route, Link } from "react-router-dom";
import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { client, repositoryName } from "./prismic";

// Styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import "swiper/css/pagination";

// Components
import { Home } from "./pages/Home";
import { NotFound } from "./pages/NotFound";
import { Thanks } from "./pages/Thanks";

/**
 * Main app component
 */
export default function App(){
  return (
    <PrismicProvider
      client={client}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/thanks" element={<Thanks />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <PrismicToolbar repositoryName={repositoryName} />
    </PrismicProvider>
  );
};
