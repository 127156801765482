import { useEffect } from "react";
import GSAP from "gsap";
GSAP.registerPlugin();

const Loader = ({ isOpen }) => {
  const onLoad = () => {
    const logo = document.querySelector(".preloader-logo");
    const title = document.querySelector(".preloader-title");
    const animateIn = GSAP.timeline();

    animateIn.to(
      [title],
      {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.5,
        y: "-10%",
        ease: "back.inOut",
      },
      "start"
    );

    animateIn.call((_) => {});
  };

  const onLoaded = () => {
    const animateOut = GSAP.timeline({
      delay: 1,
    });

    const preloader = document.querySelector(".preloader");
    const logo = document.querySelector(".preloader-logo");
    const title = document.querySelector(".preloader-title");

    animateOut.to(
      [logo, title],
      {
        autoAlpha: 0,
        duration: 1,
        stagger: 0.5,
        y: "-10%",
        ease: "back.inOut",
      },
      "start"
    );

    animateOut.to(preloader, {
      autoAlpha: 1,
      duration: 1,
      height: 0,
      delay: 0,
      transformOrigin: "top top",
      ease: "expo.out",
    });

    animateOut.call((_) => {
      preloader.style.display = "none";
    });
  };

  useEffect(() => {
    if (isOpen) {
      onLoad();
    } else {
      setTimeout(() => {
        onLoaded();
      }, 1000);
    }
  }, [isOpen]);
  return (
    <div className="preloader fixed h-screen w-screen bg-emerald-500 flex items-center z-[9999]">
      <div className="container flex flex-col md:flex-row items-center justify-center">
        <div className="w-10/12 sm:w-6/12 md:w-8/12 lg:w-6/12 flex flex-col justify-center items-center">
          <figure style={{ width: 120, height: "100%" }}>
            <svg
              className="preloader-logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 250.062 215.192"
            >
              <g transform="translate(3.957 3)">
                <path
                  className="lineAnimate"
                  d="M169.884,0H58.709l-13.5,47.912h106.62c20.244,0,32.054,2.868,27.667,18.726S162.63,85.87,139.011,85.87H34.584L0,209.192H121.8c41.5,0,84.352-12.147,95.823-54.66,6.242-22.1,0-38.8-29.017-45.719A70.855,70.855,0,0,0,241.077,58.2C251.368,20.919,219.314,0,169.884,0Zm-9.11,143.735c-4.386,15.858-15.689,17.714-58.2,17.714H75.41L85.2,126.527h27.161C155.038,126.19,165.16,128.552,160.774,143.735Z"
                  fill="rgba(255,255,255,1)"
                  stroke="#fff"
                  strokeWidth="5"
                />
              </g>
            </svg>
          </figure>
          <h3 className="preloader-title text-xl sm:text-3xl font-semibold text-center my-5 sm:my-5 text-white opacity-0">
          Escolta Blindada
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Loader;
