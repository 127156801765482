import MetaTags from "react-meta-tags";
/**
 * Default layout wrapper component
 */
export const Layout = ({ wrapperClass, data, contactData, children }) => {
  return (
    <>
      {data && (
        <MetaTags>
          <title>{`${data?.title} - ${data?.description}`}</title>
          <meta name="description" content={data?.description} />
          <meta name="keywords" content={data?.keywords} />

          <meta property="og:site_name" content={data?.title} />
          <meta property="og:image" content={data?.og_image.url} />
          <meta
            property="og:title"
            content={`${data?.title} - ${data?.description}`}
          />
          <meta property="og:description" content={data?.description} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={data?.title} />
          <meta name="twitter:description" content={data?.description} />
          <meta name="twitter:image" content={data?.og_image.url} />
        </MetaTags>
      )}
      <div className={wrapperClass}>
        {children}

        <a
          href={contactData?.button_contact_link.url}
          className="fixed bottom-5 items-center right-5 z-[10] focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-[#25d366] focus:ring-green-600 flex transition duration-150 ease-in-out hover:bg-green-600 hover:scale-105 rounded-md  text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
        >
          <img className="" src={`./whatsapp.svg`} />
          <span className="hidden sm:flex ml-2">{`Contáctanos`}</span>
        </a>
      </div>
    </>
  );
};
