import { useEffect, useState } from "react";
import { useSinglePrismicDocument } from "@prismicio/react";

import { Layout } from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

// Slices
import ClientSlice from "../slices/Clients";
import CardsSlice from "../slices/Cards";
import ReviewsSlice from "../slices/Review";
import HeroSlice from "../slices/Hero";
import ServiceSlice from "../slices/Service";
import FaqSlice from "../slices/Faq";


export const Home = () => {
  const [loader, setLoader] = useState(true);
  const [homeDoc, homeDocState] = useSinglePrismicDocument("landing");
  const [metadaDoc, metadataDocState] = useSinglePrismicDocument("metadata");
  const [contactDoc, contactDocState] = useSinglePrismicDocument("contact");
  const [navDoc, navDocState] = useSinglePrismicDocument("navigation");

  useEffect(() => {
    if (!homeDocState.state === "failed") {
      console.warn(
        "Landing document was not found. Make sure it exists in your Prismic repository"
      );
    }

    if (
      homeDocState.state === "loaded" &&
      metadataDocState.state === "loaded" &&
      contactDocState.state === "loaded" &&
      navDocState.state === "loaded"
    ) {
      setLoader(false);
    }
  }, [
    homeDocState.state,
    metadataDocState.state,
    contactDocState.state,
    navDocState.state,
  ]);

  return (
    <>
      <Loader isOpen={loader}/>

      {homeDoc?.data && (
        <Layout
          data={metadaDoc?.data}
          contactData={homeDoc?.data}
          wrapperclassName={`h-screen overflow-hidden`}
        >
          <Header data={homeDoc?.data} navigation={navDoc?.data} />
          {homeDoc?.data.body.map((slice, index) => (
            <div key={index} className="w-full">
              {slice.slice_type === "client_list" && (
                <ClientSlice data={slice} />
              )}
              {slice.slice_type === "card_list" && <CardsSlice data={slice} />}
              {slice.slice_type === "review_list" && (
                <ReviewsSlice data={slice} />
              )}
              {slice.slice_type === "hero" && <HeroSlice data={slice} />}
              {slice.slice_type === "service_list" && (
                <ServiceSlice data={slice} />
              )}
               {slice.slice_type === "feature_list" && (
                <FaqSlice data={slice} />
              )}

            </div>
          ))}
          <Footer
            data={contactDoc?.data}
            metaData={metadaDoc?.data}
            navData={navDoc?.data}
          />
        </Layout>
      )}
    </>
  );
  return null;
};
