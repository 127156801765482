import * as prismicH from "@prismicio/helpers";

const ReviewsSlice = (props) => {
  const { data } = props;

  return (
    <>
      {data && (
        <div className="w-full">
          <div
            className="relative container flex flex-col mx-auto items-center py-20 "
            id="reviews"
          >
            <div className="w-11/12 md:w-11/12 lg:w-10/12">
              <h2 className="text-3xl sm:text-4xl font-bold mb-5 text-emerald-600">
                {data?.primary.title1}
              </h2>
              <h3 className="text-md w-6/6 sm:w-2/6 mb-10 sm:mb-10 text-slate-600">
                {data?.primary.subtitle1}
              </h3>
            </div>
            <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-wrap">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-2 xl:grid-cols-2">
                {data.items.map((review, index) => (
                  <div
                    key={index}
                    className="p-10 bg-white border-gray-200 border-2 rounded-md"
                  >
                    <div className="mx-auto">
                      <div>
                        <div>
                          <figure>
                            <img
                              className="inline-block h-20 w-20 rounded-full border-4 border-emerald-500 mb-5"
                              src={review.avatar.url}
                              alt=""
                            />
                          </figure>
                        </div>
                        <p className="text-base text-body-color mb-5 text-slate-600">
                          {prismicH.asText(review.review)}
                        </p>
                        <div className="flex flex-col mb-5 text-slate-600">
                          <backquotes className="font-bold text-sm tracking-wider">
                            {review?.full_name}
                          </backquotes>
                          <backqoutes className="font-normal text-sm tracking-wider">
                            {review?.company}
                          </backqoutes>
                        </div>
                        <div className="flex">
                          {new Array(5).fill(1).map((_, t) => (
                            <svg
                              key={t}
                              width="21"
                              height="22"
                              viewBox="0 0 21 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.4995 16.1424L16.4766 19.75L14.8902 12.9513L20.1709 8.37675L13.2173 7.787L10.4995 1.375L7.78175 7.787L0.828125 8.37675L6.10875 12.9513L4.52238 19.75L10.4995 16.1424Z"
                                fill="#FF9F1C"
                              />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReviewsSlice;
