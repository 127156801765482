import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, Mousewheel } from "swiper";
import * as prismicH from "@prismicio/helpers";

const CardsSlice = (props) => {
  const { data } = props;
  const swiperBreakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 1,
      spaceBetween: 0,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2,
      spaceBetween: 50,
    },
  };

  return (
    <>
      {data && (
        <div
          className="relative container flex flex-col mx-auto items-center py-20 bg-sky-50"
          id={data?.primary.anchor}
        >
          <div className="absolute w-full bottom-0 opacity-0.5">
            <figure>
              <svg
                width="100%"
                height="100%"
                id="svg"
                viewBox="0 0 1440 600"
                xmlns="http://www.w3.org/2000/svg"
                className="transition duration-300 ease-in-out delay-150"
              >
                <defs>
                  <linearGradient
                    id="gradient3"
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                  >
                    <stop offset="5%" stopColor="#EBF8FF"></stop>
                    <stop offset="95%" stopColor="#FFFF"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 0,600 C 0,600 0,200 0,200 C 31.217855251904055,201.82939125476565 62.43571050380811,203.65878250953128 110,199 C 157.5642894961919,194.34121749046872 221.4750132366717,183.19426121664048 274,179 C 326.5249867633283,174.80573878335952 367.6642365495051,177.56417262390676 409,197 C 450.3357634504949,216.43582737609324 491.86804056530764,252.5490482877325 533,245 C 574.1319594346924,237.4509517122675 614.863601189264,186.2396342251633 651,153 C 687.136398810736,119.76036577483671 718.677554677636,104.49241481161428 760,128 C 801.322445322364,151.50758518838572 852.4261801001917,213.79070652837964 902,248 C 951.5738198998083,282.20929347162036 999.6177249215982,288.3447590748673 1053,274 C 1106.3822750784018,259.6552409251327 1165.1029202134157,224.8302571721511 1202,202 C 1238.8970797865843,179.1697428278489 1253.9705942247383,168.33421223652823 1290,170 C 1326.0294057752617,171.66578776347177 1383.0147028876308,185.83289388173588 1440,200 C 1440,200 1440,600 1440,600 Z"
                  stroke="none"
                  strokeWidth="1"
                  fill="url(#gradient3)"
                  className="transition-all duration-300 ease-in-out delay-150 wave-1"
                ></path>
                <defs>
                  <linearGradient
                    id="gradient4"
                    x1="0%"
                    y1="50%"
                    x2="100%"
                    y2="50%"
                  >
                    <stop offset="5%" stopColor="#FFF"></stop>
                    <stop offset="95%" stopColor="#EBF8FF"></stop>
                  </linearGradient>
                </defs>
                <path
                  d="M 0,600 C 0,600 0,400 0,400 C 52.33859671801373,410.202323633524 104.67719343602747,420.4046472670479 147,416 C 189.32280656397253,411.5953527329521 221.62982297390386,392.58373456533246 258,387 C 294.37017702609614,381.41626543466754 334.80351466835725,389.2604144716222 378,402 C 421.19648533164275,414.7395855283778 467.15611835266736,432.37460754817886 508,408 C 548.8438816473326,383.62539245182114 584.5720119209731,317.24115533566254 638,334 C 691.4279880790269,350.75884466433746 762.5558339634403,450.66077110917104 806,468 C 849.4441660365597,485.33922889082896 865.2046522252659,420.1157602276531 903,403 C 940.7953477747341,385.8842397723469 1000.6255571354957,416.87618798021674 1047,410 C 1093.3744428645043,403.12381201978326 1126.2931192327512,358.3794878514801 1163,359 C 1199.7068807672488,359.6205121485199 1240.2019659334997,405.60586061386283 1287,420 C 1333.7980340665003,434.39413938613717 1386.8990170332502,417.19706969306856 1440,400 C 1440,400 1440,600 1440,600 Z"
                  stroke="none"
                  strokeWidth="0"
                  fill="url(#gradient4)"
                  className="transition-all duration-300 ease-in-out delay-150 path-1"
                ></path>
              </svg>
            </figure>
          </div>
          <div className="w-11/12 sm:w-6/12 md:w-8/12 lg:w-6/12 flex flex-col justify-center items-center ">
            <h2 className="text-3xl sm:text-4xl font-bold  text-center mb-10 sm:mb-20">
              {data?.primary.title}
            </h2>
          </div>
          <div className="w-11/12 sm:w-9/12 flex flex-wrap">
            <Swiper
              modules={[Pagination, A11y, Mousewheel]}
              pagination={{ clickable: true }}
              mousewheel
              className="mySwiper"
              slidesPerView={6}
              spaceBetween={50}
              loop
              breakpoints={swiperBreakpoints}
            >
              {data.items.map((card, index) => (
                <SwiperSlide key={index}>
                  <div key={index} className="w-full px-4 mb-5">
                    <div className="max-w-[500px] mx-auto pb-10">
                      <div className="rounded-3xl overflow-hidden mb-8">
                        <img
                          src={card?.thumbnails.url}
                          alt="image"
                          className="w-full"
                        />
                      </div>
                      <div>
                        <h3 className="font-bold text-xl sm:text-2xl lg:text-2xl xl:text-3xl mb-4 inline-block text-dark hover:text-primary ">
                          {card?.title}
                        </h3>

                        <div
                          className="text-base text-body-color text-justify"
                          dangerouslySetInnerHTML={{
                            __html: prismicH.asHTML(card?.description),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default CardsSlice;
