import * as prismicH from "@prismicio/helpers";

const HeroSlice = (props) => {
  const { data } = props;

  const textTpl = () => {
    return (
      <div className="w-1/1 sm:w-5/6 mx-auto">
        <h2 className="text-3xl sm:text-6xl font-bold mb-5 text-slate-900">
          {data?.primary.title1}
        </h2>
        <div
          className="text-md text-body-color mb-10 text-slate-600 text-justify"
          dangerouslySetInnerHTML={{
            __html: prismicH.asHTML(data?.primary.description),
          }}
        />
        <a
          href={data?.primary.button_link.url}
          className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-emerald-500 focus:ring-emerald-300 transition duration-150 ease-in-out hover:bg-emerald-600 rounded-md border border-emerald-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
        >
          {data?.primary.button_text}
        </a>
      </div>
    );
  };

  const imageTpl = () => {
    return (
      <figure>
        <img
          className="inline-block h-auto w-full"
          src={data?.primary.image1.url}
          alt=""
        />
      </figure>
    );
  };

  return (
    <>
      {data && (
        <div
          className={`relative w-full  before:absolute before:bg-emerald-500 before:w-0 sm:before:w-1/3 before:h-full before:${
            data?.primary.text_align !== "Left" ? "left-0" : "right-0"
          } z-[0]`}
        >
          <div
            className={`absolute flex items-center h-full z-[3] ${
              data?.primary.text_align === "Left" ? "right-0" : "left-0"
            }`}
          >
            <svg
              className="h-full sm:h-5/6 hidden sm:flex"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 250.062 215.192"
            >
              <g transform="translate(3.957 3)">
                <path
                  className="lineAnimate"
                  d="M169.884,0H58.709l-13.5,47.912h106.62c20.244,0,32.054,2.868,27.667,18.726S162.63,85.87,139.011,85.87H34.584L0,209.192H121.8c41.5,0,84.352-12.147,95.823-54.66,6.242-22.1,0-38.8-29.017-45.719A70.855,70.855,0,0,0,241.077,58.2C251.368,20.919,219.314,0,169.884,0Zm-9.11,143.735c-4.386,15.858-15.689,17.714-58.2,17.714H75.41L85.2,126.527h27.161C155.038,126.19,165.16,128.552,160.774,143.735Z"
                  fill="none"
                  stroke="#fff"
                  strokeWidth="0.5"
                />
              </g>
            </svg>
          </div>

          <div
            className={`relative container flex flex-col mx-auto items-center`}
            id={data?.primary.anchor}
          >
            <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-wrap items-center py-10 z-1 relative">
              <div className="grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-10 lg:grid-cols-2 xl:grid-cols-2 items-center">
                <div className="">
                  {data?.primary.text_align === "Left" ? textTpl() : imageTpl()}
                </div>
                <div className="">
                  {data?.primary.text_align === "Left" ? imageTpl() : textTpl()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroSlice;
