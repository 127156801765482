import * as prismic from "@prismicio/client";

// Fill in your repository name
export const repositoryName = "bescolta";
const endpoint = prismic.getEndpoint(repositoryName);

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token.
  accessToken: "MC5ZaVBqaFJFQUFDWUFGZ1FX.77-977-977-977-9Hlvvv73vv73vv70b77-977-9ZBrvv70gLe-_ve-_vWnvv73vv70S77-9D--_vQfvv73vv73vv73vv73vv70",

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  routes: [
    {
      type: "landing",
      path: "/",
    },
  ],
});
