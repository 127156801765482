import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Mousewheel, Autoplay } from "swiper";

const ClientSlice = (props) => {
  const { data } = props;
  const swiperBreakpoints = {
    // when window width is >= 320px
    320: {
      slidesPerView: 2,
      spaceBetween: 4,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 6,
      spaceBetween: 50,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 4,
      spaceBetween: 50,
    },
  };
  return (
    <>
      {data && (
        <div className="relative container mx-auto flex justify-center py-20" id="clients">
          <div className="w-11/12 sm:w-8/12 flex flex-col justify-center items-center">
            <h3 className="text-2xl sm:text-lg font-semibold text-center mb-5 sm:mb-10">
              {data?.primary.title}
            </h3>
            <div className="w-full py-5">
              <Swiper
                autoplay
                modules={[A11y, Mousewheel, Autoplay]}
                pagination={{ clickable: true }}
                mousewheel
                className="mySwiper"
                slidesPerView={6}
                spaceBetween={50}
                loop
                breakpoints={swiperBreakpoints}
              >
                {data?.items.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="">
                      <img
                        className="w-[10rem] sm:w-[15rem] md:w-[15rem] lg:w-[15rem]"
                        src={item.image1.url}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ClientSlice;
