import { useState } from "react";
import { PrismicRichText } from "@prismicio/react";

const FaqSlice = (props) => {
  const { data } = props;
  return (
    <>
      {data && (
        <div className={`relative w-full bg-gray-50 py-10 overflow-hidden`}>
          <div
            className={`relative container flex flex-col mx-auto items-center`}
            id={"faqs"}
          >
            <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-wrap items-center py-10 z-1 relative">
              <div className="grid gap-4 grid-cols-1 md:grid-cols-1 md:gap-10 lg:grid-cols-2 xl:grid-cols-2 items-center mx-auto">
                <div className="">
                  <div className="w-1/1 sm:w-5/6 mb-5">
                    <h2 className="text-3xl sm:text-4xl font-bold mb-5 text-sm-900 text-emerald-600">
                      {data?.primary.title1}
                    </h2>
                    <p className="text-md text-body-color mb-10 text-slate-600">
                      {data?.primary.subtitle1}
                    </p>
                  </div>
                  <div className="bg-white rounded-md">
                    {data?.items.map((f, i) => (
                      <Row key={i} data={f} />
                    ))}
                  </div>
                </div>
                <div className="relative flex justify-center text-center">
                  <figure
                    className="absolute z-[0]"
                    style={{
                      right: "-75px",
                      top: "-75px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="150"
                      height="150"
                      viewBox="0 0 150 150"
                    >
                      <defs>
                        <linearGradient id="gde1" x1="0.5" x2="0.5" y2="1">
                          <stop offset="0" stopColor="#4ca585" />
                          <stop offset="1" stopColor="#f2fcff" />
                        </linearGradient>
                      </defs>
                      <circle cx="75" cy="75" r="75" fill="url(#gde1)" />
                    </svg>
                  </figure>
                  <figure
                    className="absolute z-[3]"
                    style={{
                      bottom: "-50px",
                      left: "-50px",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                    >
                      <circle cx="50" cy="50" r="50" fill="url(#gde1)" />
                    </svg>
                  </figure>
                  <figure>
                    <img
                      className="inline-block w-full sm:w-[500px]"
                      src={data?.primary.image1.url}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Row = ({ data }) => {
  const [expand, setExpand] = useState(false);
  return (
    <div className="border rounded-sm">
      <div
        onClick={() => setExpand(!expand)}
        className="p-2 py-3 flex flex-row-reverse items-center cursor-pointer text-slate-600 hover:text-emerald-600 bg-white hover:bg-white"
      >
        <div className="flex-auto text-md">{data?.title1}</div>
        <div className="px-2">
          <i
            className={`text-sm lni lni-chevron-up transition-all ease-out duration-300 text-emerald-700 ${
              expand ? "rotate-180" : "rotate-90"
            }`}
          />
        </div>
      </div>
      <div
        className={`text-sm px-4 text-justify text-slate-600 bg-white transition-all ease-in duration-100 overflow-hidden ${
          expand ? "h-full py-4 opacity-1" : "h-0 opacity-0"
        }`}
      >
        <PrismicRichText field={data?.description} />
      </div>
    </div>
  );
};

export default FaqSlice;
