import { useEffect, useState } from "react";
import GSAP from "gsap";

/**
 * Homepage Navigation component
 */
export const Sidenav = (props) => {
  const { data } = props;

  const [open, setOpen] = useState(false);

  const onOpened = () => {
    const animateIn = GSAP.timeline();

    const side = document.querySelector(".side");
    const list = document.querySelector(".side-list");

    animateIn.to(side, {
      autoAlpha: 1,
      duration: 1,
      width: "100%",
      delay: 0,
      transformOrigin: "top top",
      ease: "expo.out",
    });

    animateIn.to([...list.querySelectorAll("li")], {
      autoAlpha: 1,
      duration: 1,
      y: "0%",
      stagger: 0.1,
      ease: "back.inOut",
    });

    animateIn.call((_) => {
      // preloader.style.display = "none";
    });
  };

  const onClosed = () => {
    const animateOut = GSAP.timeline();

    const side = document.querySelector(".side");
    const list = document.querySelector(".side-list");

    animateOut.to([...list.querySelectorAll("li")], {
      autoAlpha: 0,
      duration: 1,
      y: "0%",
      stagger: 0.1,
      ease: "back.inOut",
    });

    animateOut.to(side, {
      autoAlpha: 0,
      duration: 1,
      width: 0,
      delay: 0,
      transformOrigin: "top top",
      ease: "expo.out",
    });

    animateOut.call((_) => {
      // preloader.style.display = "none";
    });
  };

  useEffect(() => {
    if (open) {
      onOpened();
    } else {
      onClosed();
    }
  }, [open]);

  useEffect(() => {
    onClosed();
  }, [0]);

  return (
    <>
      <nav
        className={`fixed w-full flex sm:hidden z-[51] bg-white border-b-2 border-emerald-600`}
      >
        <div className="py-4 md:py-0 container mx-auto px-6 flex items-center justify-between">
          <div aria-label="logo" role="img">
            <img src={data?.nav_logo.url} alt="logo" style={{ width: 150 }} />
          </div>

          <div>
            <button
              onClick={() => setOpen(!open)}
              className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-transparent focus:ring-emerald-300 block transition duration-150 ease-in-out hover:bg-emerald-500  rounded-lg border border-emerald-600 px-4 sm:px-8 py-3 sm:py-3 text-sm"
            >
              {!open ? (
                <svg
                  aria-haspopup="true"
                  aria-label="open Main Menu"
                  xmlns="http://www.w3.org/2000/svg"
                  className="md:hidden icon icon-tabler icon-tabler-menu"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#10B981"
                  fill="none"
                  strokeLinecap="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z"></path>
                  <line x1="4" y1="8" x2="20" y2="8"></line>
                  <line x1="4" y1="16" x2="20" y2="16"></line>
                </svg>
              ) : (
                <svg
                  aria-label="close main menu"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#FFF"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>
      <div className="side fixed h-screen bg-emerald-500 flex items-center z-[50]">
        <div className="container flex flex-col md:flex-row items-center justify-center ">
          <div className="w-10/12 sm:w-6/12 md:w-8/12 lg:w-6/12 flex flex-col justify-center items-center">
            <div>
              <div id="menu" className="">
                <ul className="side-list flex text-3xl md:text-base items-center py-10 md:flex flex-col md:flex-row justify-center fixed md:relative top-0 bottom-0 left-0 right-0">
                  {data?.list.map((item, index) => (
                    <li
                      key={index}
                      className="text-white hover:text-emerald-400 cursor-pointer text-2xl mt-10 md:mt-0 md:ml-5 lg:ml-10 opacity-0"
                    >
                      <a
                        href={`#${item.anchor}`}
                        onClick={() => setOpen(!open)}
                      >
                        {item?.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
