import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from "@emailjs/browser";

emailjs.init("kc82fAd3SuJqa6FqR");

/**
 * Site footer component
 */
const Footer = (props) => {
  const navigate  = useNavigate();

  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const onSend = () => {
    setLoader(true);
    emailjs
      .send("service_z82aqde", "template_nqaewdo", {
        from_name: name,
        to_name: "Escolta Blindada",
        message: message,
        reply_to: email,
      })
      .then(
        (result) => {
          setLoader(false);
          setEmail("");
          setName("");
          setMessage("");
          navigate("/thanks");
        },
        (error) => {
          setLoader(false);
          alert("Error");
        }
      )
      .catch((_) => {
        setLoader(false);
        alert("Error");
      });
  };

  return (
    <>
      {props?.data && (
        <>
          <div className="w-full bg-slate-900">
            <div
              className="container relative mx-auto flex items-center justify-center py-10 sm:py-20 border-b-4 border-emerald-500"
              id="contact"
            >
              <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-col items-start">
                <div className="grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-0 items-center">
                  <div>
                    <div className="w-full py-5">
                      <figure>
                        <img src={props?.data?.image.url} alt="logo" />
                      </figure>
                    </div>
                  </div>
                  <div>
                    <h1 className="text-5xl text-emerald-400 font-medium">
                      {props?.data.title}
                    </h1>
                    <p className="leading-relaxed mt-4 text-slate-200 w-full sm:w-5/6 mb-10">
                      {props?.data.subtitle}
                    </p>
                    <form
                      onSubmit={(ev) => {
                        ev.preventDefault();
                        onSend();
                      }}
                      type="GET"
                    >
                      <div className="relative mb-4">
                        <label
                          for="full-name"
                          className="leading-7 text-sm text-slate-50"
                        >
                          Nombre
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="w-full bg-emerald-50 rounded-md border border-gray-300 focus:border-emerald-600 focus:ring-2 focus:ring-emerald-200 text-sm outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"
                          required
                          value={name}
                          onChange={(ev) => setName(ev.target.value)}
                        />
                      </div>
                      <div className="relative mb-4">
                        <label
                          for="email"
                          className="leading-7 text-sm text-slate-50"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="w-full bg-emerald-50 rounded-md border border-gray-300 focus:border-emerald-600 focus:ring-2 focus:ring-emerald-200 text-sm outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"
                          required
                          value={email}
                          onChange={(ev) => setEmail(ev.target.value)}
                        />
                      </div>
                      <div className="relative mb-4">
                        <label
                          for="email"
                          className="leading-7 text-sm text-slate-50"
                        >
                          Mensaje
                        </label>
                        <textarea
                          rows="4"
                          cols="50"
                          id="message"
                          name="message"
                          className="w-full bg-emerald-50 rounded-md border border-gray-300 focus:border-emerald-600 focus:ring-2 focus:ring-emerald-200 text-sm outline-none text-gray-900 py-1 px-3 leading-8 transition-colors duration-150 ease-in-out"
                          required
                          value={message}
                          onChange={(ev) => setMessage(ev.target.value)}
                        ></textarea>
                      </div>
                      {loader ? (
                        <button
                          disabled
                          type="submit"
                          className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-emerald-500 focus:ring-emerald-300 block transition duration-150 ease-in-out hover:bg-emerald-600 rounded-md border border-emerald-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm opacity-50"
                        >
                          <i class="lni lni-spinner mr-3 animate-spin text-white text-sm"></i>
                          Enviando...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="focus:outline-none lg:text-md lg:font-bold focus:ring-2 focus:ring-offset-3 bg-emerald-500 focus:ring-emerald-300 block transition duration-150 ease-in-out hover:bg-emerald-600 rounded-md border border-emerald-400 text-white px-4 sm:px-8 py-3 sm:py-3 text-sm"
                        >
                          Enviar
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="bg-slate-900 text-gray-50">
            <div className="container mx-auto flex items-center justify-center">
              <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-col">
                <div className="mx-6 py-10 text-left">
                  <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="">
                      {/* <figure>
                      <img
                        src={props?.data?.logo.url}
                        alt="logo"
                        style={{ width: 150 }}
                      />
                    </figure> */}
                      <p className="text-gray-50 font-bold mb-2">
                        {props?.metaData?.title}
                      </p>
                      <p className="text-gray-400">
                        {props?.metaData?.description}
                      </p>
                    </div>
                    <div className="">
                      <h6 className="uppercase font-semibold mb-4">
                        Navegación
                      </h6>
                      {props?.navData?.list.map((item, i) => (
                        <p key={i} className="mb-4">
                          <a
                            href={item?.link}
                            className="text-gray-400 hover:text-emerald-600"
                          >
                            {item.text}
                          </a>
                        </p>
                      ))}
                    </div>
                    <div className="">
                      <h6 className="uppercase font-semibold mb-4">Social</h6>
                      {props?.data?.rrss.map((item, i) => (
                        <p key={i} className="mb-4">
                          <a
                            href={item?.link.url}
                            className="text-gray-400 hover:text-emerald-600"
                          >
                            {item.type}
                          </a>
                        </p>
                      ))}
                    </div>
                    <div className="">
                      <h6 className="uppercase font-semibold mb-4 flex">
                        Contacto
                      </h6>
                      <p className="flex items-center mb-4 text-gray-400">
                        {props?.data?.address}
                      </p>
                      <p className="flex items-center mb-4 text-gray-400 hover:text-emerald-600">
                        <a href={`mailto:${props?.data?.email}`}>
                          {props?.data?.email}{" "}
                        </a>
                      </p>
                      <p className="flex items-center mb-4 text-gray-400 hover:text-emerald-600">
                        <a
                          href={`tel:+${props?.data?.phone_number.replace(
                            /(\D)*/g,
                            ""
                          )}`}
                        >
                          {props?.data?.phone_number}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center p-1 bg-slate-900 flex flex-col md:flex-row items-center justify-center">
                  <span className="">{`2022 ${
                    new Date().getFullYear() > 2022
                      ? `- ${new Date().getFullYear()}`
                      : ""
                  } © ${props?.metaData?.title}`}</span>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </>
  );
};

export default Footer;
