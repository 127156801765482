import * as prismicH from "@prismicio/helpers";

const ServiceSlice = (props) => {
  const { data } = props;

  return (
    <>
      {data && (
        <div className="bg-gray-50 w-full">
          <div
            className="container relative mx-auto flex items-center justify-center py-10 sm:py-20"
            id="services"
          >
            <figure
              className="absolute z-[3]"
              style={{
                bottom: "-50px",
                left: "-50px",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 100 100"
              >
                <defs>
                  <linearGradient id="gde1" x1="0.5" x2="0.5" y2="1">
                    <stop offset="0" stopColor="#4ca585" />
                    <stop offset="1" stopColor="#f2fcff" />
                  </linearGradient>
                </defs>
                <circle cx="50" cy="50" r="50" fill="url(#gde1)" />
              </svg>
            </figure>
            <div className="w-11/12 md:w-11/12 lg:w-10/12 flex flex-col items-start">
              <div className="grid gap-10 grid-cols-1 md:grid-cols-1 md:gap-0 items-center">
                <div className="flex flex-col items-start">
                  <h2 className="text-4xl sm:text-5xl text-center mb-5 font-semibold text-emerald-600">
                    {data?.primary.title1}
                  </h2>
                  <h3 className="text-md mb-1 sm:mb-10 w-6/6 sm:w-2/6 text-slate-600">
                    {data?.primary.subtitle1}
                  </h3>
                </div>
                <div className="grid gap-4 grid-cols-1 md:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
                  {data.items.map((s, index) => (
                    <div key={index} className="">
                      <div className="mx-auto flex flex-col justify-start text-left p-10 border shadow-sm hover:shadow-lg border-gray-200 bg-white hover:bg-emerald-50 hover:border-emerald-700 text-emerald-600 hover:scale-105 transition-all ease-out duration-300 rounded-lg h-[100%] relative z-[2]">
                        <div className="w-full flex justify-start">
                          <figure>
                            <img
                              className="inline-block h-auto w-[100px] rounded-full ring-2 ring-emerald-600 mb-5"
                              src={s.image1.url}
                              alt=""
                            />
                          </figure>
                        </div>
                        <p className="text-xl sm:text-lg font-bold text-body-color  mb-0">
                          {s?.name}
                        </p>
                        <p className="text-md text-body-color mb-3">
                          {s?.feature}
                        </p>

                        <div
                          className="text-sm text-slate-500 text-justify w-full"
                          dangerouslySetInnerHTML={{
                            __html: prismicH.asHTML(s?.description),
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceSlice;
